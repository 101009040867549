import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>

        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
          />
          <path
            fill="url(#BG2)"
            d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
          />
          <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          />
        </g>
      </svg> */}
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="100%" height="100%" viewBox="0 0 512 512"
          preserveAspectRatio="xMidYMid meet">

        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>

          <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
          fill="#000000" stroke="none">
          <path fill="url(#BG1)" d="M1295 4574 c-340 -52 -559 -157 -776 -373 -159 -158 -257 -314 -323
          -511 -48 -146 -61 -233 -61 -415 0 -182 13 -270 61 -414 66 -197 162 -347 333
          -524 l125 -128 188 188 187 187 -108 111 c-123 126 -176 208 -221 342 -127
          381 69 803 441 952 84 34 216 61 294 61 104 0 251 -38 358 -91 85 -43 148 -99
          424 -373 l283 -280 282 280 c156 154 306 296 333 316 166 118 389 169 580 133
          333 -62 566 -293 631 -623 27 -141 12 -284 -47 -430 -41 -105 -89 -172 -206
          -292 l-102 -106 187 -187 188 -188 115 118 c215 220 322 414 381 686 28 134
          30 389 4 512 -117 535 -498 918 -1021 1025 -141 29 -366 32 -495 5 -284 -58
          -484 -165 -708 -381 l-122 -116 -124 120 c-277 267 -553 388 -906 397 -80 2
          -158 1 -175 -1z"/>
          <path fill="url(#BG2)" d="M1669 2473 c-454 -456 -842 -850 -861 -878 -51 -72 -84 -142 -114
          -242 -36 -121 -39 -290 -6 -407 62 -219 195 -383 391 -485 121 -62 215 -85
          356 -85 134 0 231 22 340 76 105 53 154 95 442 382 l283 280 287 -285 c344
          -340 400 -381 588 -430 113 -29 256 -31 364 -6 289 69 496 273 577 567 27 97
          25 281 -4 383 -25 87 -79 202 -125 262 -30 40 -1678 1695 -1687 1695 -3 0
          -377 -372 -831 -827z m1473 -560 c615 -617 637 -640 652 -692 20 -68 20 -84 1
          -149 -19 -62 -63 -115 -119 -144 -54 -28 -154 -30 -211 -3 -27 12 -191 169
          -502 479 l-463 461 -462 -462 c-255 -253 -473 -466 -484 -472 -82 -41 -195
          -32 -266 22 -88 68 -118 198 -69 297 16 32 210 232 649 673 345 345 629 627
          632 627 3 0 292 -287 642 -637z"/>
          </g>
        </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
