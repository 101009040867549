// routes
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || ''
export const STRIPE_SECRET = process.env.REACT_APP_STRIPE_SECRET || ''

export const FIREBASE_API = {
  apiKey: "AIzaSyCrzInjxqyq0T_9XJprdJFCSPcWNGCAaMY",
  authDomain: "in-loving-memory-bdadf.firebaseapp.com",
  projectId: "in-loving-memory-bdadf",
  storageBucket: "in-loving-memory-bdadf.appspot.com",
  messagingSenderId: "770142254",
  appId: "1:770142254:web:5cbd05be094842b8422fbf"
};

const app = initializeApp(FIREBASE_API);
export const storage = getStorage(app);

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAP_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.root;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_LARGE: 320,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};
