import React, { createContext, useMemo, useState } from "react";
import PropTypes from 'prop-types';

export const ShareContext = createContext({
    open: false,
    setOpen: (bool) => {},
});

const ShareContextProvider = ({ children }) => {

    const [open, setOpen] = useState(false);

    const value = useMemo(() => ({
        open,
        setOpen
    }), [open, setOpen]);

    return <ShareContext.Provider value={value}>{children}</ShareContext.Provider>
};

ShareContextProvider.propTypes = {
    children: PropTypes.node
}

export default ShareContextProvider;